// @ts-ignore
import * as jsrsasign from 'jsrsasign';
// API接口文档：https://kjur.github.io/jsrsasign/api/
import {UserToken} from '../entity/http-result';

const RANDOM_CHARS = [
  '0', '1', '2', '3', '4', '5', '6', '7', '8', '9',

  'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M',
  'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z',

  'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm',
  'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z',

  '~', '!', '@', '#', '$', '%', '^', '&', '*', '(', ')', '_', '-',
  '+', '=', '[', ']', '{', '}', '|', ';', '<', '>', '?', ',', '.'
];

export function randomString(len: number): string {
  let text = '';
  for (let i = 0; i < len; i++) {
    const pos = Math.floor(Math.random() * RANDOM_CHARS.length);
    text += RANDOM_CHARS[pos];
  }
  return text;
}

export function randomAesKey(): string {
  return randomString(32);
}

export function randomAesIV(): string {
  return randomString(16);
}

/**
 * aes加密
 * @param minwen 明文
 * @param aesKey aes加密密钥
 * @param iv aes加密的初始化向量
 * @return 返回十六进制编码的加密字符串
 */
export function aesEncrypt(minwen: string, aesKey: string, iv: string): string {
  const minwenBytes = jsrsasign.CryptoJS.enc.Utf8.parse(minwen);
  const aesKeyBytes = jsrsasign.CryptoJS.enc.Utf8.parse(aesKey);
  const ivBytes = jsrsasign.CryptoJS.enc.Utf8.parse(iv);

  const encBytes = jsrsasign.CryptoJS.AES.encrypt(minwenBytes, aesKeyBytes, {iv: ivBytes});
  return encBytes.toString();
}

/**
 * aes解密
 * @param hexEnc 十六进制编码的密文
 * @param aesKey aes加密密钥
 * @param iv aes加密的初始化向量
 * @return 返回utf编码的明文字符串
 */
export function aesDecrypt(hexEnc: string, aesKey: string, iv: string): string {
  const encBytes = jsrsasign.CryptoJS.enc.Hex.parse(hexEnc);
  const aesKeyBytes = jsrsasign.CryptoJS.enc.Utf8.parse(aesKey);
  const ivBytes = jsrsasign.CryptoJS.enc.Utf8.parse(iv);
  const minwenBytes = jsrsasign.CryptoJS.AES.decrypt({ciphertext: encBytes}, aesKeyBytes, {iv: ivBytes});
  return jsrsasign.CryptoJS.enc.Utf8.stringify(minwenBytes);
}

/**
 * 校验签名是否正确
 * @param hex 十六进制格式的加密字符串
 * @param sign 签名
 * @param publicKey 公钥
 * @return 签名正确返回true，否则返回false
 */
export function isValidSign(hex: string, sign: string, publicKey: string): boolean {
  // 校验签名是否正确
  // !要重新new 一个Signature, 否则, 取摘要和签名时取得摘要不一样, 导致验签误报失败(原因不明)!
  const signatureVf = new jsrsasign.KJUR.crypto.Signature({alg: 'SHA1withRSA', prvkeypem: publicKey});
  signatureVf.updateString(hex);
  // !接受的参数是16进制字符串!
  return signatureVf.verify(sign);
}

/**
 * 计算sha256的哈希值
 * @param text 要计算哈希值的字符串
 * @return 返回 十六进制格式的加密字符串
 */
export function signSHA256(text: string): string {
  const md = new jsrsasign.KJUR.crypto.MessageDigest({alg: "sha-256", prov: "sjcl"}); // sjcl supports sha256 only
  md.updateString(text);
  return md.digest();
}

/**
 * 将publicKey包装头和尾
 * @param publicKey publicKey的内容部分
 * @return 返回包装好的key
 */
export function wrapPublicKey(publicKey: string): string {
  return `-----BEGIN PUBLIC KEY-----\r\n${publicKey}\r\n-----END PUBLIC KEY-----`;
}

/**
 * 解析jwt字符串，获取其中的ClaimsSet
 * @param jwt jwt的字符串
 */
export function getJwtClaimsSet(jwt: string): any {
  const payload = jwt.split('.')[1];
  const base64Text = jsrsasign.b64toutf8(payload);
  return JSON.parse(base64Text);
}

export function resolveUserToken(token: string, rsaKey: any, aesKey: string, aesIv: string): UserToken {
  const loginData = token.split('o');
  const hexAccessToken = loginData[0];
  const hexRefreshToken = loginData[1];
  const sign = loginData[2];

  const validSign = isValidSign(hexAccessToken + 'o' + hexRefreshToken, sign, rsaKey);
  if (validSign) {

    const accessToken = aesDecrypt(hexAccessToken, aesKey, aesIv);
    const refreshToken = aesDecrypt(hexRefreshToken, aesKey, aesIv);
    return new UserToken(accessToken, refreshToken);
  } else {
    // 签名错误
    throw new Error('登录错误');
  }
}

export function resolveTempToken(token: string, rsaKey: any, aesKey: string, aesIv: string): string {
  const loginData = token.split('o');
  const hexAccessToken = loginData[0];
  const sign = loginData[1];

  const validSign = isValidSign(hexAccessToken, sign, rsaKey);
  if (validSign) {
    return aesDecrypt(hexAccessToken, aesKey, aesIv);
  } else {
    // 签名错误
    throw new Error('登录错误');
  }
}
