import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {HttpResult} from '../entity/http-result';
import {environment} from '../../environments/environment';
import {map} from 'rxjs/operators';
import {wrapPublicKey} from '../utils/security-utils';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class PublicKeyApi {
  constructor(private http: HttpClient) {
  }

  getPublicKey(): Observable<string> {
    // 先获取一个公钥
    return this.http.get<HttpResult<string>>(`${environment.BUSHEZHOUYE_API}/v3/security/public-key`).pipe(map((result: HttpResult<string>) => {
      if (HttpResult.succeed(result.code)) {
        // return new PublicKey(result.data!.num, wrapPublicKey(result.data!.key));
        return wrapPublicKey(result.data!);
      } else {
        throw new Error(result.message);
      }
    }));
  }
}
